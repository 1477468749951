import { type AgentConfigOptions, type ApmBase, init as apmInit } from "@elastic/apm-rum";
import type { WithRequired } from "./types/WithRequired";
export type WebObservabilityOptions = WithRequired<AgentConfigOptions, "serviceName">;
export type { WebObservability };

/**
 * Configures web observability with the given configuration.
 *
 * @param {WithRequired<AgentConfigOptions, "serviceName">} config - The configuration object for the agent, requiring the service name.
 * @returns {WebObservability | null} The WebObservability instance or null if configuration fails. If window === "undefined" returns null.
 */
export function configureWebObservability(
  config: WithRequired<AgentConfigOptions, "serviceName">,
): WebObservability | null {
  return _configureWebObservability(config);
}

export function _configureWebObservability(
  config: WithRequired<AgentConfigOptions, "serviceName">,
): WebObservability | null {
  if (typeof window === "undefined") {
    return null;
  }

  if (!config.serviceName) {
    console.error("RUM error: Service name is required");
    return null;
  }

  const builtConfig = {
    serviceVersion: "",
    environment: "local",
    transactionSampleRate: 1,
    disableInstrumentations: ["error"] as InstrumentationTypes[],
    sendCredentials: true,
    serverUrl: `${window.location.origin}`,
    serverUrlPrefix: "/dGVsZW1ldHJ5",
    ...config,
  };

  const apmInstance = apmInit(builtConfig);

  return new WebObservability(apmInstance, {
    transactionSampleRate: builtConfig.transactionSampleRate,
  });
}

class WebObservability {
  private apmInstance: ApmBase;
  private config: { transactionSampleRate: number };
  private errorHandledMap: Map<string, boolean>;

  constructor(apmInstance: ApmBase, config: { transactionSampleRate: number }) {
    this.apmInstance = apmInstance;
    this.config = config;
    this.errorHandledMap = new Map();

    if (typeof window !== "undefined") {
      window.addEventListener("error", (errorEvent) => {
        setTimeout(() => {
          if (errorEvent.error) {
            this.captureError(errorEvent.error, "Uncaught Error", { uncaught: true });
          }
        }, 2000);
      });

      window.addEventListener("unhandledrejection", (promiseRejectionEvent) => {
        setTimeout(() => {
          if (promiseRejectionEvent.reason) {
            const error: Error =
              promiseRejectionEvent.reason instanceof Error
                ? promiseRejectionEvent.reason
                : new Error(promiseRejectionEvent.reason);
            this.captureError(error, "Unhandled Rejection", { uncaught: true });
          }
        }, 2000);
      });
    }

    console.info("WebObservability: initialized");
  }

  /**
   * Captures and logs an error with additional metadata.
   *
   * @param {Error} error - The error object.
   * @param {string} errorLocation - The location of the error. Can be an error-boundary or anything that helps identifying the error.
   * @param {Object} [metadata] - Additional metadata related to the error.
   */
  captureError(
    error: Error,
    errorLocation: string,
    metadata?: { [key: string]: number | string | Date | boolean },
  ): void {
    if (!errorLocation) {
      console.error("WebObservability: Error location is required");
      return;
    }

    const errorKey = error.message;

    if (metadata?.uncaught && this.errorHandledMap.get(errorKey)) {
      console.warn("WebObservability: Error already handled");
      return;
    }

    this.errorHandledMap.set(errorKey, true);

    if (Math.random() > this.config.transactionSampleRate) {
      console.warn("WebObservability: Error was not captured due to sample rate");
      return;
    }

    const customError = {
      ...metadata,
      message: `${error?.name}: ${error?.message}`,
      name: errorLocation,
      stack: error?.stack,
    };

    this.apmInstance.captureError(customError);
  }

  /**
   * Sets the user context for APM monitoring.
   *
   * @param {Object} user - The user context object.
   * @param {string | number} [user.id] - The user ID.
   * @param {string} [user.username] - The username.
   * @param {string} [user.email] - The user email.
   */
  setUserContext(user: {
    id?: string | number;
    username?: string;
    email?: string;
  }): void {
    this.apmInstance.setUserContext(user);
  }

  /**
   * Sets custom context information for APM monitoring.
   *
   * @param {Object} context - The custom context object.
   */
  setContext(context: object): void {
    this.apmInstance.setCustomContext(context);
  }
}
